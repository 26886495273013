import { getAvatarLink } from '@src/_pandacards/helpers/deckDataMethods';
import { CardDeck } from '@src/graphql/queries';
import { IdType } from '@src/shared/generics';
import React from 'react';

interface GeneralTabContentProps {
  decks: CardDeck[];
  playerDecks: CardDeck[];
  sendDeckSelected: (cardDeckId: IdType, isChosen: boolean) => void;
}

const CardDeckTabContent: React.FC<GeneralTabContentProps> = ({ decks, playerDecks, sendDeckSelected }) => (
  <ul>
    {decks.map((deck) => {
      const isSelected = playerDecks.some((selectedDeck) => selectedDeck.id === deck.id);

      return (
        <li key={deck.id} onClick={() => sendDeckSelected(deck.id, !isSelected)}>
          <i
            style={{ backgroundImage: `url(${getAvatarLink(deck.dataLink)})` }}
            className={`${isSelected ? 'isSelected' : ''}`}
          />
          <span>{deck.name}</span>
        </li>
      );
    })}
  </ul>
);

export default CardDeckTabContent;
