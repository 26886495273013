import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  PLAYER_STATE,
  selectClient,
  selectDoctor,
  selectIsDoctor,
  selectRoomId,
  selectRoomPlayers,
} from '@store/room/roomSlice';
import { selectPlayerId } from '@store/reducers/player.reducer';
import WSClient from '@src/sockets';
import { simpleMerge } from '@src/common/generics';
import { CLIENT_EVENTS, SERVER_EVENTS } from '@shared/socketEvents/eventTypes';
import { defaultSessionState, initialMapState } from './standardSession.types';
import { StandardSessionData, StandardSessionState } from '@src/shared/sessionInterfaces/standardSession.socket';
import { IdType } from '@src/shared/generics';
import _ from 'lodash';

interface PointersData {
  merger: (state: Partial<StandardSessionState>) => StandardSessionState;
}

export const useSessionProvider = () => {
  const roomId = useSelector(selectRoomId);
  const doctor = useSelector(selectDoctor);
  const client = useSelector(selectClient);
  const isDoctor = useSelector(selectIsDoctor);
  const [session, setSession] = useState<StandardSessionState>(defaultSessionState);
  const [pointers, _1] = useState<Partial<PointersData>>({});
  pointers.merger = (state: Partial<StandardSessionState>) => simpleMerge(session, state);

  const stateMatcher = { ...initialMapState, [session.state]: true };

  console.log('SESSION_DATA', session);

  useEffect(() => {
    const updateState = ({ data }: StandardSessionData) => setSession(pointers.merger!(data.standard));

    WSClient.on(SERVER_EVENTS.ROOM_STATE_UPDATED, updateState);
    WSClient.on(SERVER_EVENTS.SESSION_STATE_UPDATED, updateState);
    // WSClient.on(SERVER_EVENTS.SESSION_STATE_PARTIAL_UPDATED, updateState);

    WSClient.emit(CLIENT_EVENTS.SESSION_STATE_REQUESTED, { roomId });

    return () => {
      // WSClient.off(SERVER_EVENTS.SESSION_STATE_PARTIAL_UPDATED, updateState);
      WSClient.off(SERVER_EVENTS.SESSION_STATE_UPDATED, updateState);
      WSClient.off(SERVER_EVENTS.ROOM_STATE_UPDATED, updateState);

      console.log('DELETE');
    };
  }, []);

  // const setLocalValue = () => setSession(simpleMerge(session, {}));

  const changeCardSlot = (cardId: IdType, slotFrom: number, slotTo: number) => {
    const from = session.slots[slotFrom];

    if (_.last(from.cards) !== cardId) {
      session.slots[slotFrom].cards.pop()!;
      session.slots[slotTo].cards.push(cardId);
    }

    setSession(pointers.merger!({ slots: session.slots }));
  };

  return {
    isState: stateMatcher,
    session: { ...session, isState: stateMatcher },
    providerActions: { changeCardSlot },
    doctor,
    client,
    isDoctor,
  };
};

export type ProviderType = ReturnType<typeof useSessionProvider>;
export type ProviderActionsType = ProviderType['providerActions'];
