import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

interface TabItem {
  label: string;
  content: React.ReactNode;
}

interface CardDeckTabsProps {
  tabs: TabItem[];
}

const CardDeckTabs: React.FC<CardDeckTabsProps> = ({ tabs }) => {
  const [value, setValue] = useState<number>(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Tabs value={value} onChange={handleChange} TabIndicatorProps={{ style: { display: 'none' } }}>
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            label={tab.label}
            sx={{
              backgroundColor: index === value ? '#F4F6FF' : 'inherit',
              fontWeight: 'bold',
              fontSize: '24px',
              borderTopRightRadius: '15px',
              textTransform: 'none',
            }}
          />
        ))}
      </Tabs>
      {tabs.map((tab, index) => (
        <TabPanel key={index} value={value} index={index}>
          {tab.content}
        </TabPanel>
      ))}
    </>
  );
};

interface TabPanelProps {
  value: number;
  index: number;
  children: React.ReactNode;
}

const TabPanel: React.FC<TabPanelProps> = ({ value, index, children }) => {
  return <>{value === index && children}</>;
};

export default CardDeckTabs;
