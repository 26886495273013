import { roomsLoadStart } from '@store/reducers/loadActions';
import { homePageLoadFinish, homePageLoadStart } from '@store/reducers/loadActions';
import { setDecks } from '@store/reducers/common';
import {
  ConfigurationQueryParams,
  ConfigurationQueryResponse,
  configurationQuery,
  StickersQueryParams,
  StickersQueryResponse,
  stickersQuery,
  BackgroundsQueryParams,
  BackgroundsQueryResponse,
  backgroundsQuery,
  AvatarsQueryParams,
  AvatarsQueryResponse,
  avatarsQuery,
  GameBalanceQueryParams,
  GameBalanceQueryResponse,
  gameBalanceQuery,
  CardDecksQueryParams,
  CardDecksQueryResponse,
  cardDecksQuery,
} from '@graphql/queries';
import { setLocale } from '@store/reducers/session.reducer';
import { AppDispatch, AppGetState } from '@app/store';
import { getDefaultStoredLocale } from '@common/misc';
import { setAvatars, setBackgrounds, setStickers, setRoomIcons, setCardBack } from '@store/room/roomFeaturesData.slice';
import { fetchGraphql } from '@src/library/fetch';
import { selectGameId } from '@src/store/room/roomSlice';

export const loadGameBalance = (dispatch: AppDispatch, getState: AppGetState) => {
  const gameId = selectGameId(getState());

  return fetchGraphql<GameBalanceQueryParams, GameBalanceQueryResponse>(gameBalanceQuery, { gameId }, true).then(
    (data) => {
      // dispatch(setGameBalance({ gameId, dailyBalance: data.game.balance.dailyBalance }));
    }
  );
};

const loadHomePageData = (dispatch: AppDispatch, getState: AppGetState) => {
  dispatch(homePageLoadStart());
  dispatch(roomsLoadStart());

  const queries = [
    fetchGraphql<CardDecksQueryParams, CardDecksQueryResponse>(cardDecksQuery, {}).then((data) => {
      dispatch(setDecks(data.cardDecks || []));
    }),

    fetchGraphql<StickersQueryParams, StickersQueryResponse>(stickersQuery, {}).then((data) => {
      dispatch(setStickers(data.stickers || []));
    }),

    fetchGraphql<AvatarsQueryParams, AvatarsQueryResponse>(avatarsQuery, {}).then((data) => {
      dispatch(setAvatars(data.avatars || []));
    }),

    fetchGraphql<BackgroundsQueryParams, BackgroundsQueryResponse>(backgroundsQuery, {}).then((data) => {
      dispatch(setBackgrounds(data.backgrounds || []));
    }),

    fetchGraphql<ConfigurationQueryParams, ConfigurationQueryResponse>(configurationQuery, {}).then((data) => {
      if (data.configuration) {
        if (!getDefaultStoredLocale()) {
          dispatch(setLocale(data.configuration.defaultLocale));
        }

        // dispatch(setRoomIcons(data.configuration.roomIcons || []));
        dispatch(setCardBack(data.configuration.cardBack || ''));
      }
    }),
  ];

  Promise.all(queries).then(() => dispatch(homePageLoadFinish()));
};

export { loadHomePageData };
