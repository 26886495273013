import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@src/app/hooks';
import { Button } from '@pc/components/ui';
import { MUTE_TYPE, setAudioAccess, setVideoAccess } from '@src/store/reducers/playerData.reducer';
import pandaColors from '@pc/helpers/colors';
import {
  TModalType,
  selectClient,
  selectIsDoctor,
  selectSidebarModals,
  setSidebarModals,
} from '@src/store/room/roomSlice';
import { ChooseDecksModal, EndSessionModal, LeaveModal, SettingsModal } from './modals';
import PandacardsLogo from '@pc/assets/images/pandacards-logo.svg';

// icons
import {
  CameraOnIcon,
  CameraOffIcon,
  CardsIcon,
  CloseIcon,
  MicrophoneOnIcon,
  MicrophoneOffIcon,
  QuitIcon,
  SettingsIcon,
} from '@pc/components/icons';
import { PersonItemClientSmall, PersonItemDoctorSmall } from '../VideoChat/parts/PersonItem/PersonItemVariants';
import { PopupState, selectDealCardsData } from '@src/store/reducers/popups';
import { styles } from './Sidebar.styles';
import { selectWrapperOffsets, setWrapperOffsets } from '@src/store/reducers/session.reducer';
import { useDeviceSize } from '@src/common/screenSizes';
import { selectCurrentPlayerVideoState } from '@src/store/reducers/common';
import ChooseDecksModalNew from './modals/ChooseDecksModalNew/ChooseDecksModalNew';

const Sidebar: React.FC = () => {
  const dispatch = useAppDispatch();
  const classes = styles();
  const { isAudioEnabled, isVideoEnabled } = useAppSelector(selectCurrentPlayerVideoState);
  // const [isAudio, setAudio] = useState(false);
  // const [isVideo, setVideo] = useState(false);
  const isClient = useAppSelector(selectClient);
  const isDoctor = useAppSelector(selectIsDoctor);
  const modalType = useAppSelector(selectSidebarModals);
  const dealCardsData = useAppSelector(selectDealCardsData);
  const { isMobile } = useDeviceSize();
  const sideBar = useRef<HTMLDivElement>(null);
  const wrapperOffsets = useAppSelector(selectWrapperOffsets);

  // Вкл./выкл. аудио
  const onAudioClick = () => {
    // setAudio(!isAudio);
    dispatch(setAudioAccess(MUTE_TYPE.OWN, !isAudioEnabled));
  };

  // Вкл./выкл. видео
  const onVideoClick = () => {
    // setVideo(!isVideo);
    dispatch(setVideoAccess(MUTE_TYPE.OWN, !isVideoEnabled));
  };

  const onToggleSidebarModals = (type: TModalType) => {
    dispatch(setSidebarModals(type));
  };

  useEffect(() => {
    // use also Resizer
    const top = isMobile ? sideBar.current?.offsetHeight || 0 : 0;
    const left = isMobile ? 0 : sideBar.current?.offsetWidth || 0;
    dispatch(setWrapperOffsets({ top, left }));
  }, [isMobile]);

  return (
    <>
      <aside className={`${classes.Sidebar} ${!isDoctor ? 'isClient' : ''}`} ref={sideBar}>
        {/* LOGO */}
        <img src={PandacardsLogo} alt="pandacards-logo.svg" />

        {/* VIDEO STREAM */}
        <PersonItemDoctorSmall />
        <PersonItemClientSmall />

        {/* MENU */}
        <ul className={`${classes.Sidebar__menu} Sidebar__menu`}>
          {/* Выкл. видео */}
          <li className={`${classes.video} video ${isVideoEnabled ? '' : 'isVideoOff'}`}>
            <div onClick={onVideoClick}>
              <i style={{ backgroundColor: isVideoEnabled ? pandaColors.black._650 : pandaColors.red }}>
                {isVideoEnabled ? <CameraOnIcon /> : <CameraOffIcon />}
              </i>

              <span>{isVideoEnabled ? 'Выкл. видео' : 'Вкл. видео'}</span>
            </div>
          </li>

          {/* Вкл. звук */}
          <li className={`${classes.sound} sound ${isAudioEnabled ? '' : 'isAudioOff'}`}>
            <div onClick={onAudioClick}>
              <i style={{ backgroundColor: isAudioEnabled ? pandaColors.black._650 : pandaColors.red }}>
                {isAudioEnabled ? <MicrophoneOnIcon /> : <MicrophoneOffIcon />}
              </i>

              <span>{isAudioEnabled ? 'Выкл. звук' : 'Вкл. звук'}</span>
            </div>
          </li>

          {/* Настройки */}
          <li className={`${classes.settings} settings`} onClick={() => onToggleSidebarModals('settings')}>
            <div>
              <i>
                <SettingsIcon />
              </i>

              <span>Настройки</span>
            </div>
          </li>

          {/* Выбрать колоду */}
          {isDoctor && (
            <li
              className={`${classes.chooseCards} chooseCards`}
              style={{ padding: '20px', marginTop: '15px', marginBottom: '20px' }}
            >
              <div onClick={() => onToggleSidebarModals('choose-deck')}>
                <i>
                  <CardsIcon />
                </i>

                <span>Выбрать колоду</span>
              </div>

              {/* Дать карту */}
              {dealCardsData.state !== PopupState.ABSENT && (
                <Button
                  type="blue"
                  onClick={() => dealCardsData.onCardsDealing!(true)}
                  // onClick={() => dealingCardsEvent()}
                  disabled={dealCardsData.state === PopupState.DISABLED}
                >
                  Дать карту
                </Button>
              )}
            </li>
          )}

          {/* Завершить сеанс */}
          {isDoctor && isClient && (
            <li className={`${classes.endSession} endSession`} onClick={() => onToggleSidebarModals('end-session')}>
              <div>
                <i>
                  <CloseIcon />
                </i>

                <span>Завершить сеанс</span>
              </div>
            </li>
          )}

          {/* Выйти */}
          <li className={`${classes.logOut} logOut`} onClick={() => onToggleSidebarModals('leave')}>
            <div>
              <i>
                <QuitIcon />
              </i>

              <span>Выйти</span>
            </div>
          </li>
        </ul>
      </aside>

      {/* Modals */}
      {/* Настройки */}
      <SettingsModal isOpen={modalType === 'settings'} closeModal={() => onToggleSidebarModals('')} />

      {/* Выбрать колоды */}
      <ChooseDecksModalNew isOpen={modalType === 'choose-deck'} closeModal={() => onToggleSidebarModals('')} />

      {/* Завершить сеанс */}
      <EndSessionModal isOpen={modalType === 'end-session'} closeModal={() => onToggleSidebarModals('')} />

      {/* Выйти */}
      <LeaveModal isOpen={modalType === 'leave'} closeModal={() => onToggleSidebarModals('')} />
    </>
  );
};

export default Sidebar;
