import { makeStyles } from '@material-ui/core';
import pandaColors from '@pc/helpers/colors';

export const styles = makeStyles((theme) => ({
  ReverseTimer__timer: {
    alignSelf: 'center',
    marginTop: '25px',
    '& > span': {
      width: '45px',
      height: '40px',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontWeight: 600,
      fontSize: '24px',
      borderRadius: '5px',
      border: `1px solid ${pandaColors.black._650}`,
      backgroundColor: pandaColors.black._900,
      '&:first-child': {
        marginRight: '2px',
      },
    },
  },
}));
