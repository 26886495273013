import { selectAvatars } from '@store/room/roomFeaturesData.slice';
import { createSelector } from 'reselect';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@app/store';
import {
  createMergeDraftReducer,
  createSimpleDraftReducer,
  createSimpleReducer,
  simpleMergeThunk,
} from '@store/reduxHelpers';
import { IdType } from '@src/shared/generics';
import { AUTH_TYPE } from '@src/shared/common';
import { IRoom } from '@src/shared/misc/room.fragment';
import {
  createDefaultEnergy,
  IEnergy,
  IPlayer,
  IPlayerData,
  IPlayerQuestQl,
  IRewards,
} from '@src/graphql/player.fragment';
import { analytics } from '@src/services/amplitude';
import { AppDispatch, AppGetState } from '@src/app/store';

export interface ILocalPlayer {
  publicId: IdType;
  name: string;
  authType: AUTH_TYPE;
}

const initialState = {
  // user: undefined as User | undefined,
  player: undefined as IPlayer | undefined,
  localPlayer: undefined as ILocalPlayer | undefined,
  room: undefined as IRoom | undefined,
  playerData: undefined as IPlayerData | undefined,
  isAuthenticated: false,
};

type StateType = typeof initialState;
type PayloadType = Partial<StateType>;
const simpleMerge = simpleMergeThunk<StateType, PayloadType>();

export const playerSlice = createSlice({
  name: 'player',
  initialState: initialState,
  reducers: {
    // setOidcUser: createSimpleDraftReducer('user'),
    setPlayer: createSimpleReducer('player'),
    setLocalPlayer: createSimpleReducer('localPlayer'),
    setPlayerRoom: createSimpleReducer('room'),
    setPlayerData: createMergeDraftReducer('playerData'),
    setIsAuthenticated: createSimpleDraftReducer('isAuthenticated'),

    updatePlayerName: (state, action: PayloadAction<string>) => {
      state.player!.name = action.payload;
      state.player!.autocreate = false;
    },
    updateCardDecks: (state, action: PayloadAction<string[]>) => {
      state.player!.cardDecks = action.payload;
    },
    updatePlayerAvatar: (state, action: PayloadAction<string>) => {
      state.player!.avatarId = action.payload;
    },
    setLocalPlayerAuthType: (state, action: PayloadAction<AUTH_TYPE>) => {
      state.localPlayer!.authType = action.payload;
    },

    setEnergy: (state, action: PayloadAction<IEnergy>) => {
      state.player!.energy = action.payload;
    },
    setEnergyAmount: (state, action: PayloadAction<number>) => {
      state.player!.energy.amount = action.payload;
    },
    subtractEnergy: (state, action: PayloadAction<number>) => {
      const energy = state.player!.energy;
      if (energy.amount >= action.payload) {
        energy.amount -= action.payload;
      }
    },
    addEnergy: (state, action: PayloadAction<number>) => {
      state.player!.energy.amount += action.payload;
    },
    setRewards: (state, action: PayloadAction<IRewards>) => {
      state.player!.rewards = action.payload;
    },
    setBalance: (state, action: PayloadAction<number>) => {
      state.player!.rewards.balance = action.payload;
    },
    setRoomIcon: (state, action: PayloadAction<string>) => {
      state.room!.icon = action.payload;
    },
    playParty: (state, action: PayloadAction<IdType>) => {
      const alreadyPlayed = state.playerData!.parties.some((party) => party.partyId === action.payload);
      if (!alreadyPlayed) {
        state.playerData!.parties.push({ partyId: action.payload, rate: 0 });
      }
    },
    rateParty: (state, action: PayloadAction<{ partyId: IdType; rate: number }>) => {
      const partyId = action.payload.partyId;
      const party = state.playerData!.parties.find((party) => party.partyId === partyId)!;
      party.rate = action.payload.rate;
    },
  },
});

export const {
  setIsAuthenticated,
  setPlayer,
  setLocalPlayer,
  setLocalPlayerAuthType,
  setPlayerRoom,
  setRoomIcon,
  setPlayerData,
  updateCardDecks,
  updatePlayerName,
  updatePlayerAvatar,
  setEnergy,
  setRewards,
  setBalance,
  playParty,
  rateParty,
} = playerSlice.actions;

const selectState = (state: RootState) => state.player || initialState;

export const selectIsAuthenticated = createSelector(selectState, (state) => state.isAuthenticated);
export const selectHasPlayer = createSelector(selectState, (state) => !!state.player);

export const selectLocalPlayer = createSelector(selectState, (state) => state.localPlayer);
export const selectLocalPlayerName = createSelector(selectLocalPlayer, (player) => player?.name);
export const selectPlayerWeak = createSelector(selectState, (state) => state.player);
export const selectPlayerIdWeak = createSelector(selectPlayerWeak, (player) => player?.publicId);
export const selectPlayer = createSelector(selectPlayerWeak, (player) => player!);
export const selectPlayerId = createSelector(selectPlayer, (player) => player.publicId);
export const selectPlayerUidId = createSelector(selectPlayer, (player) =>
  player.authType === AUTH_TYPE.UID ? player.publicId : ''
);

export const selectPlayerName = createSelector(selectPlayer, (player) => player.name);
export const selectAvatarId = createSelector(selectPlayer, (player) => player?.avatarId);
export const selectPlayerDecks = createSelector(selectPlayer, (player) => player!.cardDecks);
export const selectPlayerPrivateDecks = createSelector(selectPlayer, (player) => player!.privateCardDecks);

export const selectOwnAvatar = createSelector(selectAvatars, selectAvatarId, (avatars, avatarId) =>
  avatars.find((avatar) => avatar.id === avatarId)
);

export const selectAvatar = (avatarId: IdType | undefined) =>
  createSelector(selectAvatars, (avatars) => avatars.find((avatar) => avatar.id === avatarId));

export const selectIsPlayerAutocreated = createSelector(selectPlayerWeak, (player) => player?.autocreate || false);
export const selectIsFullAuthenticated = createSelector(
  selectPlayerWeak,
  (player) => player?.authType === AUTH_TYPE.AUTH
);

export const selectRewards = createSelector(selectPlayer, (player) => player?.rewards);
export const selectBalance = createSelector(selectRewards, (rewards) => rewards?.balance);

export const selectEnergy = createSelector(selectPlayer, (player) => player?.energy || createDefaultEnergy());
export const selectEnergyAmount = createSelector(selectEnergy, (energy) => energy?.amount);

export const selectPlayerRoom = createSelector(selectState, (state) => state.room);
export const selectPlayerRoomId = createSelector(selectPlayerRoom, (room) => room?.id);
export const selectHasPlayerRoom = createSelector(selectPlayerRoom, (room) => Boolean(room));
export const selectRoomIcon = createSelector(selectPlayerRoom, (room) => room?.icon);

export const selectPlayerData = createSelector(selectState, (state) => state.playerData!);
export const selectPlayerParties = createSelector(selectPlayerData, (playerData) => playerData?.parties || []);
export const selectPlayedPartyIds = createSelector(selectPlayerParties, (parties) =>
  parties.map((party) => party.partyId)
);
export const selectRatedParties = createSelector(selectPlayerParties, (parties) =>
  parties.filter((party) => party.rate > 0).map((party) => party.partyId)
);

export const selectQuests = createSelector(selectPlayerData, (playerData) => playerData?.quests || []);

export const selectIsPartyPlayed = (partyId: IdType) =>
  createSelector(selectPlayedPartyIds, (parties) => parties.includes(partyId));

export const hasQuestThunk = (questId: IdType) => (dispatch: AppDispatch, getState: AppGetState) => {
  const quests = selectQuests(getState());
  return quests.some((quest) => quest.id === questId);
};
