import { Hidden, makeStyles } from '@material-ui/core';
import pandaColors from '@pc/helpers/colors';

// icons
import cardBackSideImage from '@pc/assets/images/card-backside.svg';
import checkedSlotImage from '@pc/assets/icons/checked-slot.svg';
import slotDeleteImage from '@pc/assets/icons/slot-delete.png';

const { black, white, primary } = pandaColors;

export const styles = makeStyles(() => ({
  ChooseDecksModal: {
    fontFamily: 'SF Pro Display',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    color: black._900,
    width: '1002px',
    height: '805px',
  },

  ChooseDecksModal__top: {
    display: 'block',
    position: 'relative',
    width: '100%',
    padding: '20px 30px 5px',
    '& > h3': {
      color: black._900,
      textAlign: 'center',
      fontSize: '32px',
      fontWeight: 600,
      marginTop: '5px',
      marginBottom: '22px',
      letterSpacing: '0.5px',
      marginLeft: 'auto',
    },

    '& > span': {
      position: 'absolute',
      top: '20px',
      right: '30px',
      fontSize: '20px',
      fontWeight: 500,
      transition: 'all 0.23s',
      color: primary.blue,
      '&:hover': {
        cursor: 'pointer',
        color: primary.blueHover,
      },
    },
  },

  ChooseDecksModal__slots: {
    display: 'flex',
    listStyle: 'none',
    paddingLeft: 0,
    maxWidth: '100%',
    '& > li': {
      width: '84px',
      marginRight: '10px',
      flexShrink: 0,
      flexGrow: 0,
      minHeight: '150px',
      '& > i': {
        display: 'block',
        height: '120px',
        borderRadius: '5px',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      },

      // empty slot
      '&.emptySlot': {
        '& > i': {
          border: '1px solid #E7EBFE',
          backgroundColor: white._25,
          backgroundImage: `url(${cardBackSideImage})`,
        },
      },

      // filled slot
      '&.filledSlot': {
        position: 'relative',
        zIndex: 1,
        '& .delete': {
          position: 'absolute',
          zIndex: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          top: '-10px',
          right: '-10px',
          width: '20px',
          height: '20px',
          cursor: 'pointer',
          borderRadius: '50%',
          boxShadow: `1px 1px 2px 0 ${white._200}`,
          backgroundColor: 'white',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundImage: `url(${slotDeleteImage})`,
          backgroundRepeat: 'no-repeat',
        },

        '& > i': {
          borderRadius: '5px',
          marginBottom: '9px',
          transition: 'all 0.23s',
        },

        '& > span': {
          display: 'block',
          fontSize: '16px',
          fontWeight: 400,
          color: black._900,
          transition: 'all 0.23s',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      },

      '&:last-child': {
        marginRight: 0,
      },
      // '&:hover': {
      //   cursor: 'pointer',
      //   border: `1px solid ${primary.blue}`,
      // },
    },
  },

  ChooseDecksModal__bottom: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,

    // decks list
    '& > ul': {
      display: 'flex',
      flexWrap: 'wrap',
      listStyle: 'none',
      width: '100%',
      paddingLeft: '30px',
      paddingRight: '26px',
      marginBottom: 0,
      maxHeight: '48vh',
      overflowY: 'auto',
      msOverflowStyle: 'none',
      scrollbarWidth: 'none',
      backgroundColor: '#F4F6FF',
      marginBlockStart: 0,
      paddingTop: '16px',

      // y-scrollbar track width / height
      '&::-webkit-scrollbar': {
        width: '12px',
      },

      // scrollbar track background (полоска)
      '&::-webkit-scrollbar-track': {
        backgroundColor: 'transparent',
      },

      // scrollbar thumb (бегунок)
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: black._600,
        borderRadius: '8px',
      },

      // deck
      '& > li': {
        width: '120px',
        flexBasis: '120px',
        minHeight: '165px',
        flexShrink: 0,
        marginRight: '15px',
        marginBottom: '15px',
        textAlign: 'center',
        '&:nth-child(7n)': {
          marginRight: 0,
        },
        '&:hover': {
          cursor: 'pointer',
          '& > i': {
            filter: 'brightness(125%)',
          },
          '& > span': {
            color: primary.blue,
          },
        },

        // deck image
        '& > i': {
          position: 'relative',
          zIndex: 1,
          display: 'block',
          height: '165px',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'contain',
          borderRadius: '5px',
          marginBottom: '9px',
          transition: 'all 0.23s',

          '&.isSelected': {
            outline: `4px solid ${primary.blue}`,

            '&::after': {
              content: '""',
              position: 'absolute',
              zIndex: 2,
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              borderRadius: '5px',
              backgroundImage: `url(${checkedSlotImage})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.40)',
            },
          },
        },

        // deck title
        '& > span': {
          fontSize: '16px',
          fontWeight: 400,
          color: black._900,
          transition: 'all 0.23s',
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          WebkitLineClamp: 2,
        },
      },
    },
  },
}));
