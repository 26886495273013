import { IdType } from './generics';

export enum LOCALE {
  COMMON = 'COMMON',
  EN = 'EN',
  RU = 'RU',
}

export enum AUTH_TYPE {
  NONE = 'NONE',
  UID = 'UID',
  AUTH = 'AUTH',
}

export enum LoginType {
  STRICT = 'STRICT',
  CREATE_PLAYER = 'CREATE_PLAYER',
  CREATE_ROOM = 'CREATE_ROOM',
}

export enum SessionType {
  STANDARD = 'STANDARD',
}

export enum BLOCKCHAIN_TYPE {
  SOLANA = 'SOLANA',
  BSC = 'BSC',
}

export enum ROOM_JOIN_STATE {
  NO_ERROR,
  NO_ROOM,
  ROOM_FULL,
  ROOM_NOT_OPENED,
}

export interface ITimer {
  end: Date;
  left: number;
  duration: number;
  timeIsUp?: boolean;
}

export interface IDrawing {
  lines: ArrayBufferLike[];
}

export enum RewardState {
  NONE,
  PRESENT,
  NO_TREASURY,
}

export interface IReward {
  rewardState: RewardState;
  extraPlayers: number;
}

export interface ILeaderboardItem {
  name: string;
  value: number;
  place: number;
  playerId: IdType;
}

export type ILeaderboard = ILeaderboardItem[];

export enum CURRENCY {
  USD = 'USD',
  RUB = 'RUB',
}

export enum DeckType {
  IMAGE = 'IMAGE',
  WORDS = 'WORDS',
}

export enum CardType {
  TARO = 'TARO',
  MAC = 'MAC',
}
