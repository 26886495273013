import { ScrollContainer } from '@pc/components/layout';
import { MixProps } from '@src/common/generics';
import { PersonItemBig } from '../../common/VideoChat/parts/PersonItem/PersonItemVariants';
import ImagineQuestion from './ImagineQuestion/ImagineQuestion';
import { styles } from './ClientLayout.styles';
import { HookType } from '@src/_pandacards/sessions/standard/standardSession.hook';
import { CardFieldMobile } from '@src/_pandacards/sessions/standard/parts/CardField/CardFieldMobile';
import { TakeCardsMobileModal, TakeCardsModal } from '@src/_pandacards/sessions/standard/modals/TakeCards/TakeCards';
import { useAppSelector } from '@src/app/hooks';
import { selectCounterPartyIsOnline } from '@src/store/room/roomSlice';
import { LostConnection } from '@src/_pandacards/sessions/standard/parts';

interface Props {
  hook: HookType;
  // question: string;
  // content?: React.ReactNode;
}

const ClientLayoutMobile: React.FC<MixProps<Props>> = ({ hook }) => {
  const classes = styles();
  const isOtherPlayerOnline = useAppSelector(selectCounterPartyIsOnline);

  return (
    <div className={classes.ClientLayout}>
      {/* game top content for client */}
      <section className={classes.ClientLayout__top}>
        <ImagineQuestion sessionQuestion={hook.session.question} />
      </section>

      {/* center content */}
      <section className={classes.ClientLayout__center}>
        {/* game center content for client */}
        {!hook.isState.TAKE_CARD && <CardFieldMobile hook={hook} />}

        {/* doctor big video absoluted inside */}
        {!isOtherPlayerOnline ? <LostConnection isDoctor={false} /> : <PersonItemBig />}

        <TakeCardsMobileModal
          isOpen={hook.isState.TAKE_CARD}
          takeCard={hook.session.takeCard}
          onCardTaken={hook.actions.onCardTaken}
        />
      </section>
    </div>
  );
};

export { ClientLayoutMobile };
