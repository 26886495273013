import SleepPanda from '@pc/assets/images/panda-sleep.svg';
import { CopyRoomLink } from '@pc/pages/room/parts';
import { styles } from './LostConnection.styles';
import ReverseTimer from '../CardField/ReverseTimer/ReverseTimer';
import { exitToHomepage } from '@src/store/thunk/room';
import { useAppDispatch, useAppSelector } from '@src/app/hooks';
import { selectCounterPartyDisconnectDate } from '@src/store/room/roomSlice';

type TProps = {
  isDoctor: boolean;
};

const LostConnection: React.FC<TProps> = ({ isDoctor }) => {
  const classes = styles();
  const counterPartyDisconnectDate = useAppSelector(selectCounterPartyDisconnectDate);
  const timeDifferenceInMilliseconds = counterPartyDisconnectDate - Date.now();

  // calculate the difference in seconds
  const timeDifferenceInSeconds = Math.floor(timeDifferenceInMilliseconds / 1000);

  // calculate the difference in minutes
  const timeDifferenceInMinutes = Math.ceil(timeDifferenceInMilliseconds / (1000 * 60));

  return (
    <div className={`${classes.LostConnection} ${!isDoctor ? 'isClient' : ''}`}>
      <div className={`${classes.LostConnection__wrapper} LostConnection__wrapper`}>
        <img src={SleepPanda} alt="panda-sleep.svg" />
        <h2>Подключение с {`${isDoctor ? 'клиентом' : 'психологом'}`} разорвано</h2>

        {isDoctor ? (
          <>
            <p>
              У вашего клиента проблемы с интернетом или его&nbsp;устройством. Не покидайте кабинет, чтобы расклад
              карт&nbsp;и&nbsp;отбой сохранились.
            </p>

            <CopyRoomLink />
          </>
        ) : (
          <>
            <p>
              У вашего психолога проблемы с интернетом или его&nbsp;устройством. Не покидайте кабинет. Психолог
              сможет&nbsp;вернуться в кабинет, как только устранит проблемы.
              <br />
              <br />
              Если психолог не вернется в течение {timeDifferenceInMinutes} мин, кабинет будет&nbsp;закрыт
              автоматически.
            </p>

            <ReverseTimer initialTimeInSeconds={timeDifferenceInSeconds} />
          </>
        )}
      </div>
    </div>
  );
};

export default LostConnection;
