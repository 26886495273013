import { mutateGraphql } from '@src/library/fetch';
import { gql } from '@apollo/client';
import { GameTypes, GAME_LAYOUT } from '@src/common/constants/common';
import { IImage, imageFragment } from '@shared/misc/image.types';
import { IdType } from '@shared/generics';
import { CURRENCY, CardType, DeckType, IReward, LOCALE } from '@shared/common';
import { Avatar, Background, Sticker } from '@shared/misc/roomFeatures.types';
import { IVideo, videoFragment } from '@shared/misc/video.types';
import { IString, stringFragment } from '@shared/misc/string.types';
import { activeRoomFragment, IActiveRoom } from '@src/shared/misc/room.fragment';
import { BLOCKCHAIN_TYPE, ConfigRewards } from '@src/store/reducers/common';

export const gamesQuery = gql`
  query GamesQuery {
    games {
      id
      type
      name
      title
      banner
      gameIntro {
        ...ImageFragment
      }
      videoIntro {
        ...VideoFragment
      }
      description {
        ...StringFragment
      }
      rules {
        ...StringFragment
      }
      logo
      background {
        ...ImageFragment
      }
      unityData {
        link
        layout
      }
      balance {
        multiplier
        dailyBalance
        minDailyBalance
      }
    }
  }
  ${imageFragment}
  ${videoFragment}
  ${stringFragment}
`;

export type GamesQueryParams = {};

export type UnityData = {
  link: string;
  layout: GAME_LAYOUT;
};

export type GameBalance = {
  multiplier: number;
  dailyBalance: number;
  minDailyBalance: number;
};

export type Game = {
  id: string;
  type: GameTypes;
  name: string;
  title: string;
  banner: string;
  gameIntro: IImage;
  videoIntro: IVideo[];
  description: IString;
  rules: IString;
  logo: string;
  background: IImage;
  unityData?: UnityData;
  balance: GameBalance;
};

export type GamesQueryResponse = {
  games: Game[];
};

export const gameBalanceQuery = gql`
  query GameBalanceQuery($gameId: String!) {
    game(id: $gameId) {
      balance {
        dailyBalance
      }
    }
  }
`;

export type GameBalanceQueryParams = {
  gameId: IdType;
};

export type GameBalanceQueryResponse = {
  game: {
    balance: {
      dailyBalance: number;
    };
  };
};

export const gameBalancesQuery = gql`
  query GameBalancesQuery {
    games {
      id
      balance {
        dailyBalance
      }
    }
  }
`;

export type GameBalancesQueryParams = {};

export type GameBalancesQueryResponse = {
  games: {
    id: IdType;
    balance: {
      dailyBalance: number;
    };
  }[];
};

export const roomsQuery = gql`
  query RoomsQuery {
    activeRooms {
      ...ActiveRoomFragment
    }
  }
  ${activeRoomFragment}
`;

export type RoomsQueryParams = {};

export type RoomsQueryResponse = {
  activeRooms: IActiveRoom[];
};

export const creatorsQuery = gql`
  query CreatorsQuery {
    creators {
      id
      name
      avatar
      background
      description
    }
  }
`;

export type CreatorsQueryParams = {};
export type CreatorsQueryResponse = {
  creators: Creator[];
};

export type Creator = {
  id: string;
  name: string;
  avatar: string;
  background: string;
  description: string;
};

export const hostsQuery = gql`
  query HostsQuery {
    hosts {
      id
      title
      lTitle {
        ...StringFragment
      }
      tags
      game {
        id
        type
        title
        rules {
          ...StringFragment
        }
      }
      locale
      single
      rate
      price
    }
  }
  ${stringFragment}
`;

export type HostsQueryParams = {};
export type HostsQueryResponse = {
  hosts: Host[];
};

export type Host = {
  id: string;
  title: string;
  lTitle: IString;
  tags: string[];
  game: {
    id: string;
    type: GameTypes;
    title: string;
    rules: IString;
  };
  locale: LOCALE;
  single: boolean;
  rate: number;
  price: number;
};

export const sameCreatorPartiesQuery = gql`
  query creatorParties($partyId: ID!) {
    creatorParties(id: $partyId, maxCount: 2) {
      id
    }
  }
`;

export type SameCreatorPartiesParams = {
  partyId: string;
};

export type SameCreatorPartiesResponse = {
  creatorParties: SameCreatorPartiesType;
};

export type SameCreatorPartiesType = { id: string }[];

export const stickersQuery = gql`
  query StickersQuery {
    stickers {
      id
      tags
      name
      desktop
      mobile
      duration
      preview
      price
    }
  }
`;

export type StickersQueryParams = {};
export type StickersQueryResponse = {
  stickers: Sticker[];
};

export const backgroundsQuery = gql`
  query BackgroundsQuery {
    backgrounds {
      id
      backgroundColor
      gradient
      image {
        ...ImageFragment
      }
      preview
    }
  }
  ${imageFragment}
`;

export type BackgroundsQueryParams = {};
export type BackgroundsQueryResponse = {
  backgrounds: Background[];
};

export const avatarsQuery = gql`
  query AvatarsQuery {
    avatars {
      id
      image
      background
      preview
    }
  }
`;

export type AvatarsQueryParams = {};
export type AvatarsQueryResponse = {
  avatars: Avatar[];
};

export const configurationQuery = gql`
  query ConfigurationQuery {
    configuration {
      defaultLocale
      cardBack
    }
  }
`;

export interface IStreamer {
  playerId: IdType;
  name: string;
  avatar: string;
}

export type ConfigurationQueryParams = {};
export type ConfigurationQueryResponse = {
  configuration: {
    defaultLocale: LOCALE;
    roomIcons: string[];
    blockchain: {
      minClaim: number;
      maxClaim: number;
      chainType: BLOCKCHAIN_TYPE;
      solana: {
        network: string;
        token: string;
        target: string;
      };
      bsc: {
        chainId: number;
        token: string;
        target: string;
      };
    };
    draw: {
      frameFrequency: number;
      liveDrawFrequency: number;
      sendFrequency: number;
      maxLinePoints: number;
    };
    rewards: ConfigRewards;
    streamers: IStreamer[];
    resetDate: number;
    cardBack: string;
  };
};

export const createDonationPayment = gql`
  mutation createDonationPayment($amount: Float!, $currency: CURRENCY!) {
    createDonationPayment(amount: $amount, currency: $currency)
  }
`;

export interface CreateDonationParams {
  amount: number;
  currency: CURRENCY;
}

export interface CreateDonationResponse {
  createDonationPayment: IdType;
}

export const requestDonationPayment = (amount: number) => {
  return mutateGraphql<CreateDonationParams, CreateDonationResponse>(createDonationPayment, {
    amount,
    currency: CURRENCY.USD,
  }).then((data) => data.createDonationPayment);
};

export const cardDecksQuery = gql`
  query CardDecksQuery {
    cardDecks {
      id
      deckType
      name
      amount
      dataLink
      isPrivate
      cardType
    }
  }
`;

export type CardDecksQueryParams = {};

export type CardDeck = {
  id: IdType;
  deckType: DeckType;
  name: string;
  amount: number;
  dataLink: string;
  isPrivate: boolean;
  cardType: CardType;
};

export type CardDecksQueryResponse = {
  cardDecks: CardDeck[];
};
